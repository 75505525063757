import firstBenefit from "@images/common/benefit1.svg"
import thirdBenefit from "@images/common/benefit3.svg"
import hat from "@images/common/hat.svg"
import tax from "@images/common/tax.svg"
import golocker from "@images/common/goLocker-logo.png"
import lulu from "@images/common/lulu.png"

export const HEADER_BACKGROUND_IMAGE =
  "https://files.impressit.io/real_estate_technology_65be9ed72d.png"
export const HEADER_DESCRIPTION =
  "We specialize in crafting customized real estate software products and solutions that meet the specific requirements of property owners, agencies, brokers, firms, leasing companies, and tenants."
export const HEADER_TITLE = "Custom real estate software development"
export const HEADER_BUTTON_TEXT = "Let's chat"
export const MAIN_OFFERS_PRESENTATION_SECTION_TITLE =
  "Real estate software development services"
export const MAIN_OFFERS_PRESENTATION_SECTION_DESCRIPTION =
  "We offer the following services:"

export const FAQ_SECTION_ITEMS = [
  {
    title: "What is real estate software development?",
    text: "Real estate software development refers to the process of creating software applications specifically designed to cater to the needs of the real estate industry. These software are developed to provide various functionalities and services to real estate professionals, agents, buyers, sellers, and property managers. The software may include features such as property listings, search filters, virtual tours, mortgage calculators, real-time market data, and communication tools to facilitate seamless interactions between stakeholders. Real estate software development aims to enhance efficiency, convenience, and accessibility in the real estate market, empowering users to browse, buy, sell, or manage properties with ease through their mobile devices or web platforms.",
  },
  {
    title: "What software solutions are available for the real estate industry?",
    text: "In the real estate industry, a range of software solutions is available to cater to diverse needs and streamline various processes. Some common software solutions include: 1) Property Management Software, 2) Real Estate CRM (Customer Relationship Management), 3) Real Estate Listing Platforms, 4) Transaction Management Software, 5) Virtual Tour Software, 6) Property Valuation and Analytics Software, 7) Lead Generation and Marketing Software.",
  },
  {
    title: "How to find a reliable real estate software development company?",
    text: "To find a reliable real estate software development company, consider the following steps: 1. Research: Conduct thorough research online and read reviews to identify reputable companies with a track record in real estate software development. 2. Portfolio: Review the company's portfolio to assess the quality and diversity of their previous projects in the real estate industry. 3. Expertise: Check if the company has expertise in developing real estate software and if they understand the specific requirements of the industry. 4. Client Testimonials: Look for client testimonials or case studies to gauge the satisfaction of previous clients and their experiences working with the company. 5. Technical Proficiency: Ensure the company has a skilled team of developers with expertise in the latest technologies relevant to real estate software development. 6. Customization: Verify if the company can tailor the software to meet your unique business needs and preferences. 7. Communication: Evaluate the company's communication process and responsiveness to ensure effective collaboration during the development process. 8. Security Measures: Inquire about the company's security protocols and data protection measures to safeguard sensitive information. 9. Support and Maintenance: Confirm whether the company offers ongoing support and maintenance services after the software is deployed. 10. Budget and Timeline: Request detailed project proposals with estimated budgets and timelines to align with your requirements.",
  },
  {
    title: "How to estimate the time needed to develop real estate software?",
    text: "The timeframe for real estate software development typically ranges from 3 to 6+ months, depending on your specific business and technical needs. To provide accurate estimates, we tailor our assessments to your individual requirements, project scale, and complexity. Throughout the development process, we prioritize close collaboration and unwavering commitment to ensure timely delivery of your project.",
  },
  {
    title: "What information do I need to have before beginning the development of real estate software?",
    text: "The extent of your requirements depends on your project's stage and available resources for real estate software development. Nevertheless, we highly value your active involvement in defining your business model, required functionalities, business goals, and deadlines. This collaboration allows us to conduct thorough product discovery and market research. Regardless of whether you have a well-defined idea or only a rough vision of the final result, we encourage you to contact us for an individual consultation. We will delve deeper into your project, ask pertinent questions, discuss cooperation terms, and outline the subsequent steps for our collaboration. Feel free to reach out to us, and together, we can bring your real estate software project to life.",
  },
  {
    title: "What are the main differences between custom and ready-made Real Estate software?",
    text: "When embarking on real estate software development, you may face the crucial decision of choosing between custom or ready-made solutions. Drawing from our specialized industry experience, we strongly advocate for custom software for the following reasons: 1. Comprehensive Coverage: Custom software can cater to all real estate process needs in a single platform, encompassing property management, accounting, reporting, and more. 2. Seamless Upgrades and Maintenance: Implementing upgrades and carrying out maintenance becomes simpler and cost-efficient with custom software. 3. Tailored User Experience: Custom software provides an enhanced user experience tailored precisely to your target audience's needs. 4. Fewer Technical Limitations: Custom software is less constrained by technical limitations compared to ready-made solutions, offering greater flexibility. 5. Enhanced Scalability and Adaptability: Custom solutions offer improved scalability, allowing your software to adapt to future changes and business growth. By opting for custom real estate software development, your business gains a comprehensive solution that accelerates scaling, facilitates faster upgrades, and aligns with the evolving needs of your customers.",
  },
]

export const FEEDBACK_SECTION_ITEMS = [
  {
    member: "Ross Ducat",
    position: "Founder & CEO, Lulu Light",
    logoSrc: lulu,
    feedbackText: "Their speed and knowledge were impressive",
  },
  {
    member: "Sherin Davis",
    position: "Chief Product Officer, GoLocker",
    logoSrc: golocker,
    feedbackText: "Overall, they are great partners",
  },
]

export const MAIN_OFFERS_PRESENTATION_SECTION_ITEMS = [
  {
    title: "Dedicated team for real estate software development",
    text: "With our dedicated real estate software development team, you'll work closely with industry specialists who have extensive knowledge of the latest technologies. This collaboration not only keeps you updated but also saves costs by avoiding the need to hire and train in-house staff.",
  },
  {
    title: "Custom software development for real estate",
    text: "In the real estate industry, custom software development provides specialized solutions to optimize operations, boost productivity, and adapt to changing market trends. Ultimately, real estate software development plays a crucial role in achieving sustainable success and effectively meeting business goals.",
  },
]

export const MAIN_OFFERS_PRESENTATION_SECTION_UNDERSECTION_TEXT =
  "Let's discuss our potential partnership"

export const MAIN_OFFERS_PRESENTATION_SECTION_BUTTON_TEXT = "Contact us"

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_TITLE =
  "We can help you with:"

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_ITEMS = [
  {
    title: "Property Management Software (PMS)",
    text: "We help companies automate their processes, streamline front office functions, and significantly increase overall efficiency through the development of real estate software solutions.",
  },
  {
    title: "AI-driven transformation",
    text: "From predictive analytics for property valuation to AI-driven chatbots for customer interactions, our expertise empowers efficiency, data-driven decision-making, and elevated customer experiences."
  },
  {
    title: "Mobile app development",
    text: "Transform your real estate business with our cutting-edge mobile solutions, revolutionizing property transactions with user-friendly apps that provide seamless access to information, listings, and communication on-the-go.",
  },
  {
    title: "Web app development",
    text: "As a software development company, we create web solutions for the real estate industry. We focus on enhancing efficiency and convenience by providing a centralized platform connecting buyers, sellers, and real estate professionals seamlessly.",
  },
  {
    title: "IoT and Smart Homes",
    text: "Experience the future of smart living with our IoT and Smart Homes software development service. We specialize in creating cutting-edge solutions that bring intelligence and automation to your space. Embrace the future of living and build a cohesive ecosystem that enhances convenience, energy efficiency, and security.",
  },
  {
    title: "Construction tech solutions",
    text: "Our company offers a comprehensive suite of Construction technology software solutions to empower your teams with cutting-edge tools. From Building Information Modeling (BIM) and Project Management Software to Mobile Applications and Virtual/Augmented Reality (VR/AR) Software, we provide the resources your architects, engineers, and contractors need for seamless project execution.",
  },
]

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_BUTTON_TEXT = "Contact us";

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_UNDERSECTION_TEXT = "";

export const WHY_SCHOOSE_SECTION_TITLE = "Why choose Impressit?";

export const WHY_CHOOSE_ITEMS = [
  {
    title: "Full commitment",
    iconFromMarkupPath: firstBenefit,
    description:
      "At Impressit, we are equally dedicated to the success of your business and committed to supporting you throughout the entire development process.",
  },
  {
    title: "Time & costs savings",
    iconFromMarkupPath: tax,
    description:
      "Partnering with Impressit for quality development is crucial in avoiding future issues. Our experienced developers create top-notch products with comprehensive maintenance support for the lifetime of your software. Don't settle for less; prioritize security, functionality, and up-to-date technology with Impressit.",
  },
  {
    title: "Reduced Risks",
    iconFromMarkupPath: thirdBenefit,
    description:
      "While it may be tempting to hire freelance developers who offer lower hourly rates, it could end up costing you more in terms of security concerns, incomplete documentation, and subpar code.",
  },
  {
    title: "Experience",
    iconFromMarkupPath: hat,
    description:
      "It is a top priority for us to ensure that you are updated on all aspects of your software. We are delighted to provide our experience and insight to support you as you go through the production journey.",
  },
]

export const SECOND_CATCH_UP_SECTION_TITLE = "Get in touch";

export const SECOND_CATCH_UP_SECTION_DESCRIPTION = "Leave us a note and we will contact you within 24 hours";